<template>
    <div class="personal" :style="background">
        <div class="personal-warp">
            <!-- <div class="personal-title">
                <span>Home</span>&nbsp;/&nbsp;
                <span>Personal Center</span>
            </div> -->
            <div class="personal-main">
                <div class="personal-main-left" v-show="leftShow">
                    <div class="left-list">
                        <h5>{{translateTextData.Personal_Center}}</h5>
                        <router-link to="/personal/coupons">{{translateTextData.Coupons}}</router-link>
                        <!-- <span>Reward Points</span>
                        <span>Wallet</span> -->
                        <h5>{{translateTextData.My_Orders}}<img src="@/assets/images/personal/x.png" /></h5>
                        <router-link to="/personal/address">{{translateTextData.Shipping_Address}}</router-link>
                        <router-link to="/personal/wishlist">{{translateTextData.Wishlist}}</router-link>
                        <router-link to="/personal/footprint">{{translateTextData.Footprint}}</router-link>
                        <div class="list-line"></div>
                        <router-link to="/personal/shippingInfo">{{translateTextData.Shipping_Info}}</router-link>
                        <!-- <router-link to="/personal/aboutSoyeg">About SOYEGO</router-link> -->
                        <router-link to="/personal/returnPolicy">{{translateTextData.Return_Policy}}</router-link>
                        <router-link to="/personal/privacyNotice">{{translateTextData.Privacy_Notice}}</router-link>
                        <router-link to="/personal/contactUS">{{translateTextData.Contact_Us}}</router-link>
                    </div>
                    <div class="left-btn" @click="loginOut">{{translateTextData.Sign_Out}}</div>
                </div>
                <div class="personal-main-router">
                    <router-view />
                </div>
            </div>
        </div>
        <Recommended v-show="recommendedShow"></Recommended>
    </div>
</template>

<style>
.personal .swiper-pagination-1 .swiper-pagination-bullet-active{
    background: #330000;
}
.personal .swiper-pagination-2 .swiper-pagination-bullet-active{
    background: #330000;
}
.about-content{
    background: #fff;
}
</style>

<style lang="scss" scoped>
@import "./_personal/personal.scss";
</style>

<script>
import personal from "./_personal/personal.js"
import Recommended from "./components/recommended"

export default {
    name: "personal",
    components: {
        Recommended
    },
    mixins: [personal]
}
</script>