import { removeToken } from "@/utils/auth"

export default {
	name: "personal",
	components: {
		
	},
	data: () => {
		return {
			leftShow: true,
			background: "",
			recommendedShow: false,
			// 需要翻译的静态文字
            translateTextData: {
				Personal_Center: 'Personal Center',
				Coupons: 'Coupons',
				My_Orders: 'My Orders',
				Shipping_Address: 'Shipping Address',
				Wishlist: 'Wishlist',
				Footprint: 'Footprint',
				Shipping_Info: 'Shipping Info',
				Return_Policy: 'Return Policy',
				Privacy_Notice: 'Privacy Notice',
				Contact_Us: 'Contact Us',
				Sign_Out: 'Sign Out'
            }
		}
	},
	watch: {
        '$route': 'fetchData'
	},
	created() {
		this.getTranslateText();
	},
	mounted() {
		if(this.$route.path == "/personal/myOrders" || this.$route.params.i != undefined){
			this.recommendedShow = true;
		}else{
			this.recommendedShow = false;
		}
		if(this.$route.path == "/personal/myAccount" || this.$route.path == "/personal/modifyPassword"){
			this.leftShow = false;
			this.background = "background: transparent;"
		}else{
			this.leftShow = true;
			this.background = "background: #f7f7f7;"
		}
	},
	computed: {

	},
	methods: {
		// 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译文本
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i];
                        i++;
                    }
                }
            })
        },
		loginOut() {
			removeToken();
			this.$router.go(-1);
		},
		fetchData() {
			if(this.$route.path == "/personal/myOrders" || this.$route.params.i != undefined){
				this.recommendedShow = true;
			}else{
				this.recommendedShow = false;
			}
			if(this.$route.path == "/personal/myAccount" || this.$route.path == "/personal/modifyPassword"){
				this.leftShow = false;
				this.background = "background: transparent;"
			}else{
				this.leftShow = true;
				this.background = "background: #f7f7f7;"
			}
		}
	}
}
