<template>
    <div class="recommended">
        <div class="recommended-warp">
            <h5>Recommended</h5>
            <div class="list" v-loading="loadLikeing">
                <div class="item" v-for="(item, index) in likeList" :key="index">
                    <div class="img" style="width: 100%; height: 390px" @click="detailsClick(item.sku_id)">
                        <!-- contain -->
                        <el-image :src="item.goods_image[0]" fit="cover" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                    </div>
                    <span>{{ item.goods_name }}</span>
                    <p>{{ strCurXSoyego }}{{ item.discount_price }}</p>
                </div>
            </div>
            <div class="btn" @click="getGoodsList(true)">
                <button>View More</button>
            </div>
        </div>
    </div>
</template>

<script>
import { goodsSkuPage } from "@/api/goods/goods"

export default {
    name: "recommended",
    components: {},
    data: () => {
        return {
            likeList: [],
            loadLikeing: true
        }
    },
    watch: {
        $route: "fetchData"
    },
    created() {
        this.getGoodsList(false)
    },
    mounted() {},
    computed: {},
    methods: {
        getGoodsList(like) {
            this.loadLikeing = true
            const params = {
                page: 1,
                page_size: 8,
                attr_rand: true
            }
            goodsSkuPage(params || {})
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        let data = res.data
                        data.list.forEach((item) => {
                            item.goods_image = item.goods_image.split(",")
                        })
                        if (like) {
                            this.likeList = this.likeList.concat(data.list)
                        } else {
                            this.likeList = data.list
                        }
                        this.loadLikeing = false
                    }
                })
                .catch((err) => {
                    // this.loadRight = false;
                    this.$message.error(err.message);
                    this.loadLikeing = false;
                })
        },
        detailsClick(id) {
            localStorage.setItem("productDetailsDropSoyego", false)
            const blank = this.$router.resolve({ path: "/productDetails", query: { sku_id: id } })
            window.open(blank.href, "_blank")
        }
    }
}
</script>

<style lang="scss" scoped>
.recommended {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 30px 0;

    .recommended-warp {
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;

        h5 {
            font-size: 24px;
            font-family: Montserrat, SF-UI;
            font-weight: bold;
            color: #330000;
        }

        .list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .item {
                width: 24%;
                display: flex;
                flex-direction: column;
                padding-bottom: 40px;

                span {
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: 300;
                    color: #330000;
                    line-height: 1;
                    padding-top: 17px;
                    width: 80%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

                p {
                    font-size: 20px;
                    font-family: SF-UI;
                    font-weight: bold;
                    color: #330000;
                    line-height: 1;
                    padding-top: 19px;
                }
            }
        }

        .btn {
            width: 100%;
            padding-top: 40px;
            display: flex;
            justify-content: center;

            button {
                outline: none;
                border: none;
                cursor: pointer;
                width: 142px;
                line-height: 52px;
                background: #330000;
                text-align: center;
                font-size: 16px;
                font-family: Montserrat;
                font-weight: bold;
                color: #ffffff;
            }
        }
    }
}
</style>